import "./style.scss";
import { Keyboard } from "@capacitor/keyboard";
import { Network } from "@capacitor/network";
import { ModalStatus, useModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { USER_EVENTS } from "@constants/userEvent";
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonModal,
  IonRow,
  IonSpinner,
} from "@ionic/react";
import { Box, Grid } from "@mui/material";
import { Modal } from "@src/app/components/modal";
import { generateUploadTimeLog } from "@src/app/components/uploadFile/previewAndUpload";
import { DeprecatedTimesheetSummaryOverlay } from "@src/app/hcpShifts/components/timesheetSummaryOverlay";
import { DIGITAL_TIMESHEET_SIGNATURE_STEPS } from "@src/app/hcpShifts/constants";
import { calculateShiftMandatoryBreakTimes } from "@src/app/hcpShifts/helper";
import { isTimesheetChanged } from "@src/app/hcpShifts/utils/shiftTimeEditHelperText";
import { SelectedFile } from "@src/app/shiftSignature/timecard/model";
import { dataURLToBlob } from "@src/app/utils/mediaUpload";
import { isCapacitorPlatform, useToast } from "@src/appV2/lib";
import { logError, logEvent } from "@src/appV2/lib/analytics";
import { WorkplaceNoteIdentifier } from "@src/appV2/redesign/Workplace/constants";
import {
  LockScreenOrientationVariant,
  useDeprecatedScreenOrientation,
} from "@src/appV2/ScreenOrientation/index";
import { PassPhoneToSignatoryPage } from "@src/appV2/Shifts/MyShifts/components/Timesheet/PassPhoneToSignatoryPage";
import { ShiftStateData } from "@src/appV2/Shifts/Shift/ShiftState/types";
import { useGetShiftFeedbackClockOutConfig } from "@src/appV2/Shifts/Shift/useGetShiftFeedbackClockOutConfig";
import { doesShiftHaveWorkedBreak } from "@src/appV2/Shifts/Shift/utils";
import { getExtraWorkedTime } from "@src/appV2/Shifts/Shift/utils";
import { WorkedBreakAuthorization } from "@src/appV2/Shifts/Shift/WorkedBreakAuthorization/WorkedBreakAuthorization";
import { WorkedBreakAuthorizationParams } from "@src/appV2/Shifts/Shift/WorkedBreakAuthorization/WorkedBreakRequestCreationPage";
import { Shift, TimeRange } from "@src/lib/interface";
import { makeInstantpayLogParameters } from "@src/utils/logEvent";
import { submitCaliforniaTimecard, submitShiftTimeCard } from "@store/ongoingShifts";
import moment from "moment-timezone";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { CaliforniaSignature } from "./californiaSignature/californiaSignature";
import { QRSignatureFlow } from "./QRSignatureFlow";
import { ShiftTimeSummary } from "./shiftTimeSummary";
import { SignatureConfirmation } from "./signatureConfirmation";
import { SignatureConfirmationRevamp } from "./SignatureConfirmationRevamp";
import { TimeSheetSubmission } from "./timesheetSubmission";
import { ShiftDetailsAlerts } from "../shiftDetails/alerts";

const FIVE_MINUTES_IN_MS = 300_000;

interface TimeSheetSummaryProps {
  shift: Shift;
  shiftStateData?: ShiftStateData;
  onCloseOrCancel: () => void;
  onSuccessfulUpload: (params: { isWorkedBreakRequestCreated: boolean }) => void;
  isSignatureSubmission: boolean;
  showFileExplorer: boolean;
  isNewTimeSheetEnabled: boolean;
  isCaliforniaTimesheetEnabled: boolean;
  isSolveUnpaidBreaksEnabled: boolean;
  shiftDetailsAlerts: ShiftDetailsAlerts;
}

export function TimeSheetSummary(props: TimeSheetSummaryProps) {
  const {
    shift,
    shiftStateData,
    onCloseOrCancel,
    onSuccessfulUpload,
    isSignatureSubmission,
    showFileExplorer,
    isNewTimeSheetEnabled,
    isCaliforniaTimesheetEnabled,
    isSolveUnpaidBreaksEnabled,
    shiftDetailsAlerts,
  } = props;

  const facilityId = shift.facilityId ?? "";
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [isKeyboardVisible, setIsKeyboardVisible] = useState<boolean>(false);
  const [isTimeEditButtonVisible, setIsTimeEditButtonVisible] = useState(true);

  const shiftTimeSummaryModalState = useModalState();
  const unsavedChangesModalState = useModalState();
  const passPhoneToSignatoryModalState = useModalState();
  const {
    shiftFeedbackClockOutFlowIsEnabled,
    qrCodeForClockOutIsEnabled,
    namePageBoldedMessage,
    qrCodeFeedbackBeforeClockOutIsEnabled,
  } = useGetShiftFeedbackClockOutConfig(facilityId);
  const shiftFeedbackEnableAndNotInCalifornia =
    !isCaliforniaTimesheetEnabled && shiftFeedbackClockOutFlowIsEnabled;
  const [isOnFinalStageOfSigningFlow, setIsOnFinalStageOfSigningFlow] = useState<boolean>(false);

  const { lockOrientation, unlockOrientation } = useDeprecatedScreenOrientation();

  const workedBreakAuthorizationModalState = useModalState();
  const [workedBreakAuthorization, setWorkedBreakAuthorization] = useState<
    WorkedBreakAuthorizationParams | undefined
  >(undefined);

  const { showErrorToast } = useToast();

  const dispatch = useDispatch();

  const { note: mandatoryBreakPolicyNoteContent } =
    shift.facility?.facilityNotes?.find(
      (note) => note.identifier === WorkplaceNoteIdentifier.MANDATORY_BREAK_POLICY
    ) ?? {};

  const isMandatoryBreakEnabled = shiftStateData?.metadata.requiresLunchBreak ?? false;

  useEffect(() => {
    if (isCapacitorPlatform()) {
      const keyboardDidShowListener = Keyboard.addListener("keyboardDidShow", () => {
        setIsKeyboardVisible(true);
      });
      const keyboardDidHideListener = Keyboard.addListener("keyboardDidHide", () => {
        setIsKeyboardVisible(false);
      });
      return () => {
        keyboardDidShowListener.then((listenerHandle) => listenerHandle.remove());
        keyboardDidHideListener.then((listenerHandle) => listenerHandle.remove());
      };
    }
  }, []);

  const originalClockInOut = {
    start: shift?.clockInOut?.start,
    end: shift?.clockInOut?.end ?? new Date().toISOString(),
  };
  const originalLunchInOut = {
    start: shift?.lunchInOut?.start,
    end: shift?.lunchInOut?.end,
  };

  const [submitClockInOut, setSubmitClockInOut] = useState<TimeRange>(originalClockInOut);

  if (
    isSolveUnpaidBreaksEnabled &&
    isMandatoryBreakEnabled &&
    (!isDefined(originalLunchInOut.start) || !isDefined(originalLunchInOut.end))
  ) {
    const defaultBreakTimes = calculateShiftMandatoryBreakTimes(shift);
    if (isDefined(defaultBreakTimes)) {
      originalLunchInOut.start = defaultBreakTimes.start.toISOString();
      originalLunchInOut.end = defaultBreakTimes.end.toISOString();
    }
  }
  const [submitLunchInOut, setSubmitLunchInOut] = useState<TimeRange>(originalLunchInOut);

  /**
   * These are clock in/out times set by the user in ShiftTimeSummary
   * when there is missing worked break authorization data. We keep these
   * times here in "staging" until the HCP adds data why there was a worked break
   * time recorded (break was skipped or shorter than 30 minutes).
   * When the HCP adds this data, we save these times to submitClockInOut and submitLunchInOut.
   */
  const [stagingSubmitClockInOut, setStagingSubmitClockInOut] = useState<TimeRange | undefined>();
  const [stagingSubmitLunchInOut, setStagingSubmitLunchInOut] = useState<TimeRange | undefined>();

  function isMissingWorkedBreakAuthorization(
    clockInOut?: TimeRange,
    lunchInOut?: TimeRange
  ): boolean {
    const doesHaveWorkedBreak = doesShiftHaveWorkedBreak({
      clockInOut: clockInOut ?? {},
      lunchInOut: lunchInOut ?? {},
    });

    return (
      isSolveUnpaidBreaksEnabled &&
      isMandatoryBreakEnabled &&
      doesHaveWorkedBreak &&
      !isDefined(workedBreakAuthorization)
    );
  }

  const onShiftTimeSave = (
    submitClockIn?: string,
    submitClockOut?: string,
    submitLunchOut?: string,
    submitLunchIn?: string
  ) => {
    if (
      isMissingWorkedBreakAuthorization(
        { start: submitClockIn, end: submitClockOut },
        { start: submitLunchOut, end: submitLunchIn }
      )
    ) {
      setStagingSubmitClockInOut({
        start: submitClockIn,
        end: submitClockOut,
      });
      setStagingSubmitLunchInOut({
        start: submitLunchOut,
        end: submitLunchIn,
      });
      workedBreakAuthorizationModalState.openModal();
      void lockOrientation(LockScreenOrientationVariant.PORTRAIT_PRIMARY);
      logEvent(
        USER_EVENTS.EXTRA_WORKED_TIME_REQUEST_FORM_OPENED,
        makeInstantpayLogParameters(shift, shift.isInstantPay, shift.agent)
      );
    } else {
      const doesHaveWorkedBreak = doesShiftHaveWorkedBreak({
        clockInOut: {
          start: submitClockIn,
          end: submitClockOut,
        },
        lunchInOut: {
          start: submitLunchOut,
          end: submitLunchIn,
        },
      });
      if (!doesHaveWorkedBreak) {
        setWorkedBreakAuthorization(undefined);
      }
      setSubmitClockInOut({
        start: submitClockIn,
        end: submitClockOut,
      });
      setSubmitLunchInOut({
        start: submitLunchOut,
        end: submitLunchIn,
      });
      shiftTimeSummaryModalState.closeModal();
    }
  };

  const closeTimesheetSummary = () => {
    const isChanged = isTimesheetChanged({
      originalClockInOut,
      originalLunchInOut,
      editedClockInOut: submitClockInOut,
      editedLunchInOut: submitLunchInOut,
    });
    unsavedChangesModalState.setModalStatus(isChanged ? ModalStatus.OPEN : ModalStatus.CLOSED);

    if (!isChanged) {
      onCloseOrCancel();
    }
  };

  const submitTimesheetWithSignature = async (
    submittedFile: SelectedFile | null,
    facilityEmployeeName: string
  ) => {
    // Network check
    const status = await Network.getStatus();
    if (!status.connected) {
      setIsUploading(false);
      shiftDetailsAlerts.alertNoNetworkConnection({ isEdit: true });
      return;
    }
    //UploadFile
    uploadFile(submittedFile, facilityEmployeeName);
    //Submit Edited time - Saving it to Db (Submit_ clock_in and out,lunch in and out)
  };

  const uploadFile = async (submittedFile: SelectedFile | null, digitallySignedBy: string) => {
    if (!submittedFile) {
      setIsUploading(false);
      return;
    }
    const { file, type: fileType } = submittedFile;
    const fileBlob = await dataURLToBlob(file!);
    const uploadTimeStart = moment();
    try {
      logEvent(
        USER_EVENTS.UPLOAD_TIMESHEET_STARTED,
        generateUploadTimeLog({
          shift,
          file,
          uploadTimeStart,
          isUploadEnd: false,
          isTimeSheetEditDisabled: shiftStateData?.metadata.isTimeSheetEditDisabled,
        })
      );

      const extraWorkedTime = getExtraWorkedTime(
        submitClockInOut,
        submitLunchInOut,
        workedBreakAuthorization?.reason
      );
      await Promise.race([
        new Promise((_, reject) => setTimeout(() => reject(), FIVE_MINUTES_IN_MS)),
        dispatch(
          submitShiftTimeCard({
            file,
            submitClockInOut,
            submitLunchInOut,
            shiftId: shift._id as string,
            digitallySignedBy,
            fileType,
            fileBlob,
            extraWorkedTime,
          })
        ),
      ]);
      if (shift.isInstantPay) {
        logEvent(USER_EVENTS.SUBMITTED_INSTANTPAY_TIMECARD_PHOTO, {
          instant_pay: shift?.isInstantPay,
        });
      }

      setIsUploading(false);
      logEvent(USER_EVENTS.UPLOAD_TIMESHEET_SUCCESS, {
        ...generateUploadTimeLog({
          shift,
          file,
          uploadTimeStart,
          isUploadEnd: true,
          isTimeSheetEditDisabled: shiftStateData?.metadata.isTimeSheetEditDisabled,
        }),
      });
      onSuccessfulUpload({ isWorkedBreakRequestCreated: isDefined(extraWorkedTime) });
    } catch (err) {
      const error = err as Error | string;
      logError("Timesheet summary uploadFile error", { error });
      logEvent(
        USER_EVENTS.UPLOAD_TIMESHEET_FAILED,
        generateUploadTimeLog({
          shift,
          file,
          uploadTimeStart,
          isUploadEnd: true,
          error,
          isTimeSheetEditDisabled: shiftStateData?.metadata.isTimeSheetEditDisabled,
        })
      );
      if (error?.toString().includes("Request has been terminated")) {
        showErrorToast("Uploading failed, please try again");
      } else {
        showErrorToast(error?.["message"] || error);
      }
      setIsUploading(false);
    }
  };

  const submitCaliforniaTimesheet = async (
    submittedFiles: SelectedFile[] | null,
    digitallySignedByHCP: string | undefined,
    digitallySignedBy: string,
    stationWingUnitFloor: string,
    nursingServiceAssignment: string
  ) => {
    if (!digitallySignedByHCP || !submittedFiles || submittedFiles?.length < 2 || !shift._id) {
      setIsUploading(false);
      return;
    }
    const uploadTimeStart = moment();
    try {
      logEvent(
        USER_EVENTS.UPLOAD_CALIFORNIA_TIMESHEET_STARTED,
        generateUploadTimeLog({
          shift,
          file: submittedFiles[0].file,
          uploadTimeStart,
          isUploadEnd: false,
          isTimeSheetEditDisabled: shiftStateData?.metadata.isTimeSheetEditDisabled,
        })
      );

      const extraWorkedTime = getExtraWorkedTime(
        submitClockInOut,
        submitLunchInOut,
        workedBreakAuthorization?.reason
      );

      await Promise.race([
        new Promise((_, reject) => setTimeout(() => reject(), FIVE_MINUTES_IN_MS)),
        dispatch(
          submitCaliforniaTimecard({
            shiftId: shift._id,
            submittedFiles,
            submitClockInOut: submitClockInOut,
            submitLunchInOut: submitLunchInOut,
            digitallySignedByHCP,
            digitallySignedBy,
            stationWingUnitFloor,
            nursingServiceAssignment,
            extraWorkedTime,
          })
        ),
      ]);
      setIsUploading(false);
      logEvent(USER_EVENTS.UPLOAD_CALIFORNIA_TIMESHEET_SUCCESS, {
        ...generateUploadTimeLog({
          shift,
          file: submittedFiles[0].file,
          uploadTimeStart,
          isUploadEnd: true,
          isTimeSheetEditDisabled: shiftStateData?.metadata.isTimeSheetEditDisabled,
        }),
      });
      onSuccessfulUpload({ isWorkedBreakRequestCreated: isDefined(extraWorkedTime) });
    } catch (err) {
      const error = err as Error | string;
      logError("Timesheet summary submitCaliforniaTimesheet error", { error });
      logEvent(
        USER_EVENTS.UPLOAD_CALIFORNIA_TIMESHEET_FAILED,
        generateUploadTimeLog({
          shift,
          file: submittedFiles[0].file,
          uploadTimeStart,
          isUploadEnd: true,
          error,
          isTimeSheetEditDisabled: shiftStateData?.metadata.isTimeSheetEditDisabled,
        })
      );
      if (error?.toString().includes("Request has been terminated")) {
        showErrorToast("Uploading failed, please try again");
      } else {
        showErrorToast(error?.["message"] || error);
      }
      setIsUploading(false);
    }
  };

  const renderShiftTimeSummary = () => {
    return (
      <ShiftTimeSummary
        shift={shift}
        isEditable={shiftTimeSummaryModalState.modalIsOpen}
        isTimeEditButtonVisible={isTimeEditButtonVisible}
        originalClockInOut={originalClockInOut}
        originalLunchInOut={originalLunchInOut}
        initialSubmissionClockInOut={submitClockInOut}
        initialSubmissionLunchInOut={submitLunchInOut}
        onShiftTimeSave={onShiftTimeSave}
        onShiftTimeEdit={(isOpen) =>
          isOpen ? shiftTimeSummaryModalState.openModal() : shiftTimeSummaryModalState.closeModal()
        }
        isNewTimeSheetEnabled={isNewTimeSheetEnabled}
        isEarlyClockInEnabled={shiftStateData?.metadata.isEarlyClockInEnabled === true}
        isSolveUnpaidBreaksEnabled={isSolveUnpaidBreaksEnabled}
        isMandatoryBreakEnabled={isMandatoryBreakEnabled}
        isTimeSheetEditDisabled={shiftStateData?.metadata.isTimeSheetEditDisabled ?? false}
        qrCodeFeedbackBeforeClockOutIsEnabled={qrCodeFeedbackBeforeClockOutIsEnabled}
      />
    );
  };

  const renderTimesheetUi = () => {
    if (!shift._id) {
      return <IonSpinner />;
    }

    if (isCaliforniaTimesheetEnabled) {
      return (
        <CaliforniaSignature
          shift={shift}
          lunchInOutTimeRange={submitLunchInOut}
          isUploadingImage={isUploading}
          isShiftTimeEditOpen={shiftTimeSummaryModalState.modalIsOpen}
          isSolveUnpaidBreaksEnabled={isSolveUnpaidBreaksEnabled}
          workedBreakAuthorization={workedBreakAuthorization}
          onCloseOrCancel={closeTimesheetSummary}
          onSubmit={(...args) => {
            setIsUploading(true);
            submitCaliforniaTimesheet(...args);
          }}
          onOpenTimeEdit={() => shiftTimeSummaryModalState.openModal()}
          onStepChange={(currentStep) => {
            if (currentStep === DIGITAL_TIMESHEET_SIGNATURE_STEPS.HCF_SIGNATURE) {
              passPhoneToSignatoryModalState.openModal();
            }
            if (isSolveUnpaidBreaksEnabled) {
              setIsTimeEditButtonVisible(
                currentStep === DIGITAL_TIMESHEET_SIGNATURE_STEPS.HCP_SIGNATURE
              );
            }
          }}
        />
      );
    }

    if (qrCodeFeedbackBeforeClockOutIsEnabled) {
      return (
        <QRSignatureFlow
          shiftId={shift._id}
          clockInOut={submitClockInOut}
          lunchInOut={submitLunchInOut}
          workerId={shift.agentId ?? ""}
          breakAuthorizationReason={workedBreakAuthorization?.reason}
          facilityId={facilityId}
          instructionBoldedMessage={namePageBoldedMessage}
          onSuccess={() => {
            onCloseOrCancel();
          }}
        />
      );
    }

    if (isSignatureSubmission && shiftFeedbackClockOutFlowIsEnabled) {
      return qrCodeForClockOutIsEnabled &&
        isDefined(shift._id) &&
        isDefined(submitClockInOut.start) &&
        isDefined(submitClockInOut.end) ? (
        <QRSignatureFlow
          shiftId={shift._id}
          clockInOut={submitClockInOut}
          workerId={shift.agentId ?? ""}
          lunchInOut={submitLunchInOut}
          breakAuthorizationReason={workedBreakAuthorization?.reason}
          facilityId={facilityId}
          instructionBoldedMessage={namePageBoldedMessage}
          onSuccess={() => {
            onSuccessfulUpload({ isWorkedBreakRequestCreated: false });
          }}
        />
      ) : (
        <SignatureConfirmationRevamp
          onSubmit={(file, facilityEmployeeName) => {
            setIsUploading(true);
            submitTimesheetWithSignature(file, facilityEmployeeName);
          }}
          lunchInOutTimeRange={submitLunchInOut}
          isUploadingImage={isUploading}
          isShiftTimeEditOpen={shiftTimeSummaryModalState.modalIsOpen}
          isSolveUnpaidBreaksEnabled={isSolveUnpaidBreaksEnabled}
          workedBreakAuthorization={workedBreakAuthorization}
          onStepChange={(currentStep) => {
            if (currentStep === DIGITAL_TIMESHEET_SIGNATURE_STEPS.HCF_SIGNATURE) {
              void lockOrientation(LockScreenOrientationVariant.PORTRAIT_PRIMARY);
              passPhoneToSignatoryModalState.openModal();
            }
            if (isSolveUnpaidBreaksEnabled) {
              setIsTimeEditButtonVisible(
                currentStep === DIGITAL_TIMESHEET_SIGNATURE_STEPS.HCP_SIGNATURE
              );
            }
            setIsOnFinalStageOfSigningFlow(
              currentStep === DIGITAL_TIMESHEET_SIGNATURE_STEPS.HCF_SIGNATURE
            );
          }}
          onOpenTimeEdit={() => shiftTimeSummaryModalState.openModal()}
          isPassPhoneOpen={passPhoneToSignatoryModalState.modalIsOpen}
          clockInOut={submitClockInOut}
          shift={shift}
          setIsOnFinalStageOfSigningFlow={setIsOnFinalStageOfSigningFlow}
          namePageBoldedMessage={namePageBoldedMessage}
        />
      );
    }
    if (isSignatureSubmission) {
      return (
        <SignatureConfirmation
          onSubmit={(file, facilityEmployeeName) => {
            setIsUploading(true);
            submitTimesheetWithSignature(file, facilityEmployeeName);
          }}
          lunchInOutTimeRange={submitLunchInOut}
          isUploadingImage={isUploading}
          isShiftTimeEditOpen={shiftTimeSummaryModalState.modalIsOpen}
          isSolveUnpaidBreaksEnabled={isSolveUnpaidBreaksEnabled}
          workedBreakAuthorization={workedBreakAuthorization}
          onStepChange={(currentStep) => {
            if (currentStep === DIGITAL_TIMESHEET_SIGNATURE_STEPS.HCF_SIGNATURE) {
              passPhoneToSignatoryModalState.openModal();
            }
            if (isSolveUnpaidBreaksEnabled) {
              setIsTimeEditButtonVisible(
                currentStep === DIGITAL_TIMESHEET_SIGNATURE_STEPS.HCP_SIGNATURE
              );
            }
          }}
          onOpenTimeEdit={() => shiftTimeSummaryModalState.openModal()}
        />
      );
    }
    return (
      <TimeSheetSubmission
        shift={shift}
        onSubmit={(submittedFile: SelectedFile | null) => {
          //UploadFile
          uploadFile(submittedFile, "");
          //Submit Edited time - Saving it to Db (Submit_ clock_in and out,lunch in and out)
        }}
        isShiftTimeEditOpen={shiftTimeSummaryModalState.modalIsOpen}
        showFileExplorer={showFileExplorer}
        shiftDetailsAlerts={shiftDetailsAlerts}
      />
    );
  };

  return (
    <IonContent>
      <IonModal isOpen swipeToClose onDidDismiss={closeTimesheetSummary}>
        <IonContent fullscreen>
          {passPhoneToSignatoryModalState.modalIsOpen && (
            <DeprecatedTimesheetSummaryOverlay>
              <PassPhoneToSignatoryPage
                onClose={() => {
                  passPhoneToSignatoryModalState.closeModal();
                  setIsOnFinalStageOfSigningFlow(true);
                }}
              />
            </DeprecatedTimesheetSummaryOverlay>
          )}
          {isSolveUnpaidBreaksEnabled && workedBreakAuthorizationModalState.modalIsOpen && (
            <DeprecatedTimesheetSummaryOverlay>
              <WorkedBreakAuthorization
                onCancel={() => {
                  workedBreakAuthorizationModalState.closeModal();
                  if (!shiftFeedbackEnableAndNotInCalifornia) {
                    void lockOrientation(LockScreenOrientationVariant.LANDSCAPE_SECONDARY);
                  }
                }}
                onSubmit={(data) => {
                  setWorkedBreakAuthorization(data);
                  if (isDefined(stagingSubmitClockInOut) && isDefined(stagingSubmitLunchInOut)) {
                    setSubmitClockInOut(stagingSubmitClockInOut);
                    setSubmitLunchInOut(stagingSubmitLunchInOut);
                  }
                  workedBreakAuthorizationModalState.closeModal();
                  if (!shiftFeedbackEnableAndNotInCalifornia) {
                    void lockOrientation(LockScreenOrientationVariant.LANDSCAPE_SECONDARY);
                  }
                  shiftTimeSummaryModalState.closeModal();
                  logEvent(
                    USER_EVENTS.EXTRA_WORKED_TIME_REQUEST_FORM_SAVED,
                    makeInstantpayLogParameters(shift, shift.isInstantPay, shift.agent)
                  );
                }}
                facilityName={shift.facility?.name ?? "The workplace"}
                mandatoryBreakPolicy={mandatoryBreakPolicyNoteContent ?? ""}
              />
            </DeprecatedTimesheetSummaryOverlay>
          )}

          {shiftFeedbackEnableAndNotInCalifornia ? (
            <Box
              sx={{
                backgroundColor: (theme) =>
                  isOnFinalStageOfSigningFlow ? "transparent" : theme.palette.grey[300],
                height: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Grid
                container
                sx={{
                  padding: 0,
                  flexDirection: "column",
                  paddingTop: "env(safe-area-inset-top)",
                  flex: 1,
                  width: "100%",
                }}
              >
                {!isOnFinalStageOfSigningFlow && (
                  <Grid item sx={{ padding: "20px", marginBottom: "20px", width: "100%" }}>
                    {renderShiftTimeSummary()}
                  </Grid>
                )}
                <Grid item sx={{ padding: "20px", backgroundColor: "#fff", flex: 1 }}>
                  {renderTimesheetUi()}
                </Grid>
              </Grid>
            </Box>
          ) : (
            <IonGrid style={{ padding: 0, height: "100%" }}>
              <IonRow style={{ height: "100%" }}>
                <IonCol size="4" className="shift-event-log-content">
                  {renderShiftTimeSummary()}
                </IonCol>
                <IonCol style={{ padding: "20px" }}>{renderTimesheetUi()}</IonCol>
              </IonRow>
            </IonGrid>
          )}
          {passPhoneToSignatoryModalState.modalIsClosed && !isKeyboardVisible && (
            <IonButton
              aria-label="Close timesheet summary"
              fill="clear"
              routerDirection="back"
              color="dark"
              size="small"
              style={{
                position: "absolute",
                top: "env(safe-area-inset-top)",
                right: "5px",
                zIndex: 9999,
                margin: 0,
                "--padding-start": "8px",
                "--padding-end": "8px",
              }}
              onClick={closeTimesheetSummary}
            >
              <IonIcon icon="close" />
            </IonButton>
          )}
        </IonContent>
      </IonModal>
      <Modal
        titleHtml={
          <>
            <h1>Hold up!</h1> <h2>You&apos;ve edited the timesheet.</h2>
          </>
        }
        cancelText="Cancel"
        confirmText="Close this page"
        onDismiss={() => unsavedChangesModalState.closeModal()}
        onConfirm={() => {
          void unlockOrientation();
          onCloseOrCancel();
        }}
        isOpen={unsavedChangesModalState.modalIsOpen}
      >
        <div className="ion-padding-top">
          If you close this page, any changes you made to the timesheet will be discarded.
        </div>
      </Modal>
    </IonContent>
  );
}
